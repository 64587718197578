import { Box, Button, Container, Flex, Heading, Stack, SystemStyleInterpolation, Text } from '@chakra-ui/react'
import { default as NextLink } from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import type { FunctionComponent } from 'react'

import Image from 'components/elements/Image'
import useAuthState from 'components/modules/Auth/hooks/useAuthState'
import { RoutePaths } from 'config/routes'
import useBusinessUnitCapabilitites from 'utils/Providers/BusinessUnit/useBusinessUnitCapabilitites'

/**
 * Used for E2E tests.
 */
const HERO_HEIGHT = { base: 180, sm: 407 }
const HERO_WIDTH = { base: 360, sm: 668 }

const DEFAULT_IMAGE = {
  src: '/images/hero_banner.jpg',
  blurDataURL:
    // eslint-disable-next-line max-len
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAECAIAAAAI1ii7AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAz0lEQVR4nAHEADv/AFJdakIwNlYqLv+Ui/yflIhvcmtZZYpqdHpcZHBdaJmTnoxdX2o5NYqfs77X82BodgAqJixJNTlVKS3daVz/r5v/3sf6vbTBi4i0lZtdQ05GPkpTNzlBJCVLVmUfHSUMBgsAIRUVGRMUTCIljlpW55uJo19R/bKhyntzhlJSTC0zKCQoNiUnTTIuKRwfZ1RDyraUAAsFBgUBAy4nJ3OBeVtMQx8GBJdhVqpuYnY5MkgnJg0FBjEgIFs4Mk49ONrOp9jQqUEhSIq+3QqqAAAAAElFTkSuQmCC',
}

const styles = {
  heroContainer: {
    display: 'flex',
    alignItems: 'center',
    px: { base: 0, md: 4 },
    flexDirection: { base: 'column-reverse', sm: 'row' },
    mb: '8',
  },

  heroText: {
    lineHeight: '0.8',
    letterSpacing: '-0.015em',
    fontWeight: 'nomral',
    fontSize: { base: 'xl', lg: '2.813rem' },
  },
  heroContent: {
    w: { base: 'full', sm: '50%' },
    flexDirection: 'column',
    py: '8',
    px: { base: 4, md: 0 },
    pr: { md: 4 },
  },
  image: {
    maxW: '100%',
    marginInline: 'auto',
    gridArea: '1 / -1',
    minH: '100%',
    minWidth: '100%',
    userSelect: 'none',
  },
} as const satisfies SystemStyleInterpolation

const buttonLinks = [
  { translateKey: 'components.hero.viewOrders', href: RoutePaths.orders },
] as const satisfies ReadonlyArray<ObjectWithTranslationKeyAndRoutePath>

const buttonLinksCanPutOrder = [
  { translateKey: 'components.hero.reorderPrevious', href: RoutePaths.orders },
  {
    translateKey: 'components.hero.seeAssortment',
    href: RoutePaths.products,
  },
  { translateKey: 'components.hero.viewShoppingLists', href: RoutePaths.shoppingLists },
] as const satisfies ReadonlyArray<ObjectWithTranslationKeyAndRoutePath>

const HeroHome: FunctionComponent = () => {
  const { t } = useTranslation()
  const { customer } = useAuthState(true)
  const businessUnitCapabilitites = useBusinessUnitCapabilitites()

  const links = businessUnitCapabilitites?.canPutOrder === true ? buttonLinksCanPutOrder : buttonLinks

  return (
    <Container {...styles.heroContainer}>
      <Flex {...styles.heroContent}>
        <Box textTransform="uppercase" borderLeft="8px" borderColor="primary.red" pl="4" mb="6">
          <Text {...styles.heroText} mb="4">
            {t('components.hero.welcomeBack')}
          </Text>
          <Heading as="h1" color="primary.red" {...styles.heroText}>
            {customer.firstName}
          </Heading>
        </Box>

        <Box>
          <Text fontSize={{ base: 'md', lg: '1.375rem' }} mb="6">
            {t('components.hero.whatToDo')}
          </Text>
          <Stack spacing={{ base: '2', md: '4' }} direction={{ base: 'column', lg: 'row' }}>
            {links.map(({ translateKey, href }) => (
              <Button key={translateKey} as={NextLink} href={href} px="5" maxW={{ sm: '240px' }}>
                {t(translateKey)}
              </Button>
            ))}
          </Stack>
        </Box>
      </Flex>
      <Flex w={{ base: 'full', sm: '50%' }} height={HERO_HEIGHT}>
        <Image
          alt=""
          role="presentation"
          priority={true}
          loading="eager"
          width={HERO_WIDTH}
          height={HERO_HEIGHT}
          {...styles.image}
          {...(typeof DEFAULT_IMAGE === 'string' ? {} : DEFAULT_IMAGE)}
          src={typeof DEFAULT_IMAGE === 'string' ? DEFAULT_IMAGE : DEFAULT_IMAGE.src}
        />
      </Flex>
    </Container>
  )
}

export default HeroHome
