import { List } from '@chakra-ui/react'
import type { ProductProjection } from '@commercetools/platform-sdk'
import React, { FunctionComponent } from 'react'

import ProductsListItem from 'components/modules/Products/ProductsList/ProductsListItem'
import AddToShoppingListProvider from 'components/modules/ShoppingList/AddToShoppingList/AddToListProvider'

interface RecentlyViewedListProps {
  products: ReadonlyArray<ProductProjection>
}

const TAG = 'RecentlyViewedList'

const RecentlyViewedList: FunctionComponent<RecentlyViewedListProps> = ({ products }) => (
  <AddToShoppingListProvider>
    <List spacing={2} mb={20}>
      {products?.map((product) => (
        <ProductsListItem key={product.id} productKey={product.key} {...product} />
      ))}
    </List>
  </AddToShoppingListProvider>
)

RecentlyViewedList.displayName = TAG

export default RecentlyViewedList
