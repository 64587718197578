import { Button, Icon } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'

import LatestOrdersIcon from 'assets/latest_orders.svg?component'
import getOrderHistory from 'azure/order/getOrderHistory'
import Error from 'components/elements/Error'
import SectionBlockHeader from 'components/elements/SectionBlockHeader'
import SectionLoading from 'components/elements/SectionLoading'
import OrderHistoryFormWrapper from 'components/modules/OrderHistory/OrderHistoryFormWrapper'
import OrderHistoryTable from 'components/modules/OrderHistory/OrderHistoryTable'
import { RoutePaths } from 'config/routes'
import useBusinessUnit from 'utils/Providers/BusinessUnit/useBusinessUnit'
/**
 * Used for E2E tests.
 */
const TAG = 'OrderHistoryBlock'

/**
 * Component for displaying all orders.
 * @see RoutePaths.OrderHistory
 */

const NUMBER_OF_ROWS_TO_SHOW = 5

const OrderHistoryBlock: FunctionComponent = () => {
  const { t } = useTranslation('orders')

  const businessUnit = useBusinessUnit()
  const erpCustomerNumber = businessUnit.data.current?.custom?.fields.erpCustomerNumber

  const response = useQuery(['getOrderHistory', erpCustomerNumber, 1, NUMBER_OF_ROWS_TO_SHOW], getOrderHistory, {
    enabled: !!erpCustomerNumber,
  })

  if (response.isError) {
    return (
      <Error
        title={t('pages.orderHistory.error.title')}
        description={t('pages.orderHistory.error.description')}
        buttonText={t('pages.orderHistory.error.button')}
        buttonOnClick={response.refetch}
      />
    )
  }

  const orders: OrderHeader[] = response.data?.orders || []

  return (
    <OrderHistoryFormWrapper>
      <SectionBlockHeader
        title={t('pages.orderHistory.block.title')}
        icon={
          <Icon
            as={LatestOrdersIcon}
            boxSize="1.25rem"
            color="gray.500"
            mb="1"
            mr="2"
            display={{ base: 'none', sm: 'block' }}
          />
        }
      >
        <Button as={Link} href={RoutePaths.orders}>
          {t('pages.orderHistory.block.button')}
        </Button>
      </SectionBlockHeader>
      {response.isLoading ? <SectionLoading /> : <OrderHistoryTable orders={orders} />}
    </OrderHistoryFormWrapper>
  )
}

OrderHistoryBlock.displayName = TAG

export default OrderHistoryBlock
