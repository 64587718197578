import { Td, Tr, Box, Flex } from '@chakra-ui/react'
import type { ProductProjection } from '@commercetools/platform-sdk'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent, useContext } from 'react'

import LeadTime from 'components/elements/LeadTime'
import Price from 'components/elements/Price'
import ProductLink from 'components/elements/ProductLink'
import { ResponsiveTableContext } from 'components/elements/ResponsiveTable/Provider/context'
import ProductAddToCart from 'components/modules/Product/ProductAddToCart'
import AddToShoppingListButton from 'components/modules/ShoppingList/AddToShoppingList/Button'

interface RecentlyViewedTableRowProps extends Omit<ProductProjection, 'key'> {
  // we use `productKey`, because we cannot use `key` as a prop name.
  // @see https://reactjs.org/warnings/special-props.html
  productKey: ProductProjection['key']
}
/**
 * Used for E2E tests.
 */
const TAG = 'RecentlyViewedTableRow'

const RecentlyViewedTableRow: FunctionComponent<RecentlyViewedTableRowProps> = ({
  id,
  productKey,
  categories,
  masterVariant,
}) => {
  const { t } = useTranslation('common')

  const headings = useContext(ResponsiveTableContext)

  const { erpFacility, erpWarehouse, erpMinQuantity } = masterVariant?.price?.custom?.fields || {}

  return (
    <Box as={Tr} data-testid={TAG} data-product-id={id} data-product-key={productKey}>
      <Td>
        <ProductLink productKey={productKey} masterVariant={masterVariant} categories={categories} />
      </Td>
      <Td data-label={headings[1].title}>{productKey}</Td>
      <Td data-label={headings[2].title}>
        <LeadTime masterVariant={masterVariant} />
      </Td>
      <Td data-label={headings[3].title}>
        <Price price={masterVariant?.price?.value} />
      </Td>

      <Td paddingRight={{ md: 0 }}>
        <Flex w="full" justifyContent="space-between" mt={{ base: '4', sm: '0' }}>
          <AddToShoppingListButton
            productId={id}
            variantId={masterVariant.id}
            erpFacility={erpFacility}
            erpWarehouse={erpWarehouse}
            quantity={1}
            mr="2"
          />
          <ProductAddToCart
            productId={id}
            variantId={masterVariant.id}
            quantity={erpMinQuantity || 1}
            erpFacility={erpFacility}
            erpWarehouse={erpWarehouse}
            w="full"
          >
            {t('components.cart.purchaseNow')}
          </ProductAddToCart>
        </Flex>
      </Td>
    </Box>
  )
}

RecentlyViewedTableRow.displayName = TAG

export default RecentlyViewedTableRow
