/**
 * The maximum number of recently viewed items to store in the local storage.
 */
export const RECENTLY_VIEWED_MAX_ITEMS = 5

/**
 * Used as a key for the recently viewed items in the local storage.
 */
export const RECENTLY_VIEWED_STORAGE_KEY = 'recentlyViewed'

/**
 * The type of the recently viewed items stored in the local storage.
 */
export type RecentlyViewedKeys = ReadonlyArray<string>
