
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { Container, Icon, Stack } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'

import RecentlyViewedIcon from 'assets/recently_viewed.svg?component'
import CategoryMenu from 'components/elements/CategoryMenu'
import SectionBlockHeader from 'components/elements/SectionBlockHeader'
import HeroHome from 'components/modules/Hero/HeroHome'
import OrderHistoryBlock from 'components/modules/OrderHistory/OrderHistoryBlock'
import RecentlyViewedContent from 'components/modules/RecentlyViewedItems/RecentlyViewedContent'
import getRevalidate from 'config/revalidate'
import withPageStaticProps from 'utils/next/withPageStaticProps'
import useBusinessUnitCapabilitites from 'utils/Providers/BusinessUnit/useBusinessUnitCapabilitites'

interface StartPageProps {}

const TAG = 'Home'

const StartPage: NextPageWithLayout<StartPageProps> = () => {
  const { t } = useTranslation('common')
  const businessUnitCapabilitites = useBusinessUnitCapabilitites()
  return (
    <>
      <HeroHome />
      <Container
        data-testid={TAG}
        maxW="desktop"
        px={{ base: '0', sm: '8' }}
        pb="10"
        display="flex"
        justifyContent="space-between"
        flexWrap={{ base: 'wrap', xl: 'nowrap' }}
        alignItems="flex-start"
        flexDirection={{ base: 'column-reverse', xl: 'row' }}
      >
        <Stack w="full" direction="column" spacing="8">
          <OrderHistoryBlock />
          {businessUnitCapabilitites.canPutOrder && (
            <RecentlyViewedContent>
              <SectionBlockHeader
                title={t('components.recentlyViewed.title')}
                icon={
                  <Icon
                    as={RecentlyViewedIcon}
                    boxSize="1.5rem"
                    color="gray.500"
                    mb="1"
                    mr="2"
                    display={{ base: 'none', sm: 'block' }}
                  />
                }
              />
            </RecentlyViewedContent>
          )}
        </Stack>
        {businessUnitCapabilitites.canPutOrder && <CategoryMenu />}
      </Container>
    </>
  )
}

export default StartPage

 const _getStaticProps = withPageStaticProps<StartPageProps>(async ({ locale }) => {
  if (locale === 'default' || !locale) {
    return { notFound: true }
  }

  return {
    props: {},
    revalidate: getRevalidate(),
  }
})


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index.page',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  