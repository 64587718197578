import type { ProductProjection } from '@commercetools/platform-sdk'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'

import ResponsiveTable from 'components/elements/ResponsiveTable'
import type { TableHeadingType } from 'components/elements/ResponsiveTable/Provider/context'
import RecentlyViewedTableRow from 'components/modules/RecentlyViewedItems/RecentlyViewedTableRow'
import AddToShoppingListProvider from 'components/modules/ShoppingList/AddToShoppingList/AddToListProvider'

interface RecentlyViewedTableProps {
  products: ReadonlyArray<ProductProjection>
}

/**
 * Used for E2E tests.
 */
const TAG = 'RecentlyViewedTable'

const RecentlyViewedTable: FunctionComponent<RecentlyViewedTableProps> = ({ products }) => {
  const { t } = useTranslation('common')

  const tableHeadings = [
    { title: t('components.recentlyViewed.table.product') },
    { title: t('components.recentlyViewed.table.itemNo') },
    { title: t('components.recentlyViewed.table.leadTime') },
    { title: t('components.recentlyViewed.table.priceUnit') },
    { title: t('components.recentlyViewed.table.cart'), isHidden: true },
  ] as ReadonlyArray<TableHeadingType>

  return (
    <AddToShoppingListProvider>
      <ResponsiveTable headings={tableHeadings}>
        {products.map((product) => (
          <RecentlyViewedTableRow key={product.id} productKey={product.key} {...product} />
        ))}
      </ResponsiveTable>
    </AddToShoppingListProvider>
  )
}

RecentlyViewedTable.displayName = TAG

export default RecentlyViewedTable
