import { Box } from '@chakra-ui/react'
import type { ProductProjection } from '@commercetools/platform-sdk'
import { useQuery } from '@tanstack/react-query'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent, ReactNode } from 'react'

import getProductsByKeys from 'commercetools/products/getProductsByKeys'
import Error from 'components/elements/Error'
import SectionLoading from 'components/elements/SectionLoading'
import RecentlyViewedList from 'components/modules/RecentlyViewedItems/RecentlyViewedList'
import RecentlyViewedTable from 'components/modules/RecentlyViewedItems/RecentlyViewedTable'
import { RecentlyViewedKeys, RECENTLY_VIEWED_STORAGE_KEY } from 'config/recentlyViewed'
import useStateCache from 'hooks/useStateCache'
import useBlanketAgreements from 'utils/Providers/BlanketAgreements/useBlanketAgreements'
import useBusinessUnit from 'utils/Providers/BusinessUnit/useBusinessUnit'

/**
 * Used for E2E tests.
 */
const TAG = 'RecentlyViewedContent'

interface RecentlyViewedContentProps {
  children: ReactNode
  mode?: 'table' | 'list'
}

const RecentlyViewedContent: FunctionComponent<RecentlyViewedContentProps> = ({ children, mode = 'table' }) => {
  const { t } = useTranslation('common')
  const businessUnit = useBusinessUnit()
  const blanketAgreements = useBlanketAgreements()
  const recentlyViewedBusinessUnitKey = `${RECENTLY_VIEWED_STORAGE_KEY}-${businessUnit.data.key}`
  const [recentlyViewedKeys] = useStateCache<RecentlyViewedKeys>(recentlyViewedBusinessUnitKey, [])

  const response = useQuery(
    [
      getProductsByKeys.name,
      businessUnit.data?.key,
      recentlyViewedKeys,
      recentlyViewedBusinessUnitKey,
      blanketAgreements?.data?.id,
      blanketAgreements?.data?.currency,
    ],
    getProductsByKeys,
    { enabled: businessUnit.isSuccess, refetchOnMount: false },
  )

  if (response.isError) {
    return (
      <Error
        title={t('components.recentlyViewed.error.title')}
        description={t('components.recentlyViewed.error.description')}
        buttonText={t('components.recentlyViewed.error.button')}
        buttonOnClick={response.refetch}
      />
    )
  }

  const products: ProductProjection[] | undefined = response.data?.body.results || []

  return (
    <Box>
      {children}
      {response.isLoading ? (
        <SectionLoading />
      ) : products.length > 0 ? (
        <>
          {mode === 'table' && <RecentlyViewedTable products={products} />}
          {mode === 'list' && <RecentlyViewedList products={products} />}
        </>
      ) : (
        <Error title={t('components.recentlyViewed.empty.title')} height="auto" />
      )}
    </Box>
  )
}

RecentlyViewedContent.displayName = TAG

export default RecentlyViewedContent
