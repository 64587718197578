import { Box, Flex, Heading } from '@chakra-ui/react'
import React, { FunctionComponent, ReactNode } from 'react'

interface SectionBlockHeaderBaseProps {
  title: string
  icon?: ReactNode
  children?: ReactNode
}

/**
 * Used for E2E tests.
 */
const TAG = 'SectionBlockHeader'

const SectionBlockHeader: FunctionComponent<SectionBlockHeaderBaseProps> = ({ title, icon, children }) => {
  return (
    <Box
      as="header"
      aria-label={title}
      marginBlockEnd="2"
      paddingInline={{ base: '4', md: '0' }}
      paddingBlockEnd="2"
      borderBottomWidth={{ base: '0', md: '1px' }}
      borderColor="gray.300"
    >
      <Flex wrap="wrap" direction="row" align="center">
        {icon}
        <Heading as="h2" size={{ base: '1.25rem', md: 'lg' }} mr="auto" textTransform="uppercase">
          {title}
        </Heading>
        {children}
      </Flex>
    </Box>
  )
}

SectionBlockHeader.displayName = TAG

export default SectionBlockHeader
