import type {
  ClientResponse,
  ProductProjectionPagedQueryResponse,
  ProductProjectionPagedSearchResponse,
} from '@commercetools/platform-sdk'
import type { QueryFunctionContext } from '@tanstack/react-query'

import { EMPTY_CLIENT_RESPONSE_PAGED } from 'commercetools/const'
import getInitialFilterQuery from 'commercetools/utils/getInitialFilterQuery'
import { RoutePaths } from 'config/routes'
import apiAuthFetch from 'utils/apiAuthFetch'
import stringifyObjectValues from 'utils/stringifyObjectValues'

type QueryKey = [
  queryFnName: string,
  businessUnitKey: string,
  productKeys: string[] | null | unknown,
  recentlyViewedBusinessUnitKey: string,
  blanketAgreementsId: string,
  priceCurrency: string,
]

type ProductsByKeysQuery = Pick<QueryFunctionContext<QueryKey>, 'signal' | 'queryKey'>

/**
 * @returns a list of products
 * @link https://docs.commercetools.com/api/projects/products#query-products
 */
function getProductsByKeys({
  queryKey: [, businessUnitKey, productKeys, , blanketAgreementId, priceCurrency],
}: ProductsByKeysQuery): Promise<ClientResponse<ProductProjectionPagedQueryResponse>> {
  const keys: string[] = Array.isArray(productKeys) ? productKeys : []

  if (keys.length === 0) {
    return Promise.resolve(EMPTY_CLIENT_RESPONSE_PAGED)
  }

  const stringifiedParams = stringifyObjectValues({
    expand: 'categories[*]',
    where: `key in ("${keys.join('","')}")`,
    'filter.query': getInitialFilterQuery(blanketAgreementId),
    limit: keys.length,
    priceCustomerGroup: blanketAgreementId,
    priceCurrency: priceCurrency,
    businessUnitKey,
  })

  const params = new URLSearchParams(stringifiedParams).toString()

  const url = `${RoutePaths.productProjectionsApi}?${params}`
  return apiAuthFetch<ClientResponse<ProductProjectionPagedSearchResponse>>(url)
}

export default getProductsByKeys
