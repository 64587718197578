import { Box, Button, Icon, Stack, SystemStyleInterpolation } from '@chakra-ui/react'
import NextLink from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'

import ChevronIcon from 'assets/chevron_right.svg?component'
import FindIcon from 'assets/find.svg?component'
import isRootCategory from 'commercetools/utils/isRootCategory'
import Image from 'components/elements/Image'
import SectionBlockHeader from 'components/elements/SectionBlockHeader'
import getCategoryImageByName from 'components/modules/Products/utils/getCategoryImageByName'
import { RoutePaths } from 'config/routes'
import useInitialFacets from 'hooks/useInitialFacets'
import useLocalizedString from 'hooks/useLocalizedString'
import assignParamsToUrl from 'utils/assignParamsToUrl'

/**
 * Used for E2E tests.
 */
const TAG = 'CategoryMenu'

const DEFAULT_WH = 48

const styles = {
  box: {
    w: 'full',
    position: { base: 'relative', xl: 'sticky' },
    top: '0',
    ml: { base: '0', xl: '6' },
    maxW: { base: 'full', md: 'side-block' },
    borderColor: 'gray.200',
    mb: '6',
  },
  button: {
    border: '1px solid',
    borderColor: 'gray.300',
    justifyContent: 'flex-start',
    h: '16',
    fontSize: 'md',
    pl: '2',
  },
} as const satisfies SystemStyleInterpolation

const CategoryMenu: FunctionComponent = () => {
  const { t } = useTranslation()
  const display = useLocalizedString()
  const { categories } = useInitialFacets()

  return (
    <Box data-testid={TAG} {...styles.box}>
      <SectionBlockHeader
        title={t('pages.homepage.findYourProducts')}
        icon={<Icon as={FindIcon} boxSize="1.5rem" color="gray.500" mr="2" display={{ base: 'none', sm: 'block' }} />}
      />
      <Stack w="full" px={{ base: 4, xl: 0 }}>
        {categories
          ?.slice(1)
          .filter(isRootCategory)
          .map((category) => (
            <Button
              data-id={category.id}
              key={display(category.name)}
              as={NextLink}
              href={
                category.isMainRoot
                  ? RoutePaths.products
                  : assignParamsToUrl(RoutePaths.productsCategory, { slug: display(category.slug) })
              }
              leftIcon={
                <Image
                  src={getCategoryImageByName(display(category.name))}
                  width={DEFAULT_WH}
                  height={DEFAULT_WH}
                  alt={display(category.name)}
                />
              }
              rightIcon={<Icon aria-hidden="true" as={ChevronIcon} boxSize={2} />}
              {...styles.button}
            >
              {display(category.name)}
            </Button>
          ))}
        <Button w="full" size="lg" mt="4" as={NextLink} href={RoutePaths.products}>
          {t('pages.homepage.seeAllProducts')}
        </Button>
      </Stack>
    </Box>
  )
}

CategoryMenu.displayName = TAG

export default CategoryMenu
